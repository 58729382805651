const AndroidIcon = (): JSX.Element => {
  return (
    <>
      <div className="iconBox">
        <svg preserveAspectRatio="xMinYMin meet" viewBox="0 0 30 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27.867 10.635C26.7 10.635 25.7333 11.587 25.7333 12.743V20.9409C25.7333 22.0969 26.667 23.0497 27.867 23.0497C29.0333 23.0497 30 22.0969 30 20.9401V12.7438C29.967 11.5878 29.0333 10.6358 27.867 10.6358V10.635ZM2.133 10.635C0.9675 10.635 0 11.587 0 12.7422V20.9409C0 22.0969 0.933 23.0497 2.133 23.0497C3.333 23.0497 4.26675 22.0969 4.26675 20.9401V12.7438C4.26675 11.5878 3.333 10.6358 2.133 10.6358V10.635ZM5.5665 10.703V25.7017C5.5665 26.5857 6.3 27.3337 7.233 27.3337H9.1335V31.8912C9.1335 33.048 10.0665 34 11.2665 34C12.4335 34 13.4003 33.048 13.4003 31.8912V27.3337H16.7333V31.8912C16.7333 33.048 17.667 34 18.867 34C20.0325 34 21 33.048 21 31.8912V27.3337H22.8998C23.8335 27.3337 24.567 26.6197 24.567 25.7017V10.7022H5.5665V10.703ZM19.5 2.94791L21.267 0.499132C21.333 0.329132 21.333 0.125131 21.1665 0.0571313C21.033 -0.0448688 20.8335 0.0231313 20.733 0.159131L18.933 2.70991C17.6714 2.24156 16.341 2.00004 15 1.99591C13.5998 1.99591 12.267 2.23391 11.067 2.70991L9.267 0.159904C9.1665 -0.0100961 8.967 -0.0448688 8.8335 0.0571313C8.76857 0.107217 8.72518 0.181442 8.71257 0.263999C8.69996 0.346555 8.71913 0.430922 8.766 0.499132L10.533 2.94868C7.767 4.20668 5.8005 6.58746 5.5665 9.37624H24.534C24.234 6.58669 22.2998 4.20668 19.5 2.94791ZM11.0003 6.82546C10.4003 6.82546 9.96675 6.38346 9.96675 5.80546C9.96675 5.22668 10.4333 4.78546 11.0003 4.78546C11.6003 4.78546 12.033 5.22668 12.033 5.80546C12.033 6.38346 11.5665 6.82546 11.0003 6.82546ZM19.1333 6.82546C18.5333 6.82546 18.0998 6.38346 18.0998 5.80546C18.0998 5.22668 18.567 4.78546 19.1333 4.78546C19.7333 4.78546 20.1667 5.22668 20.1667 5.80546C20.1667 6.38346 19.7003 6.82546 19.1333 6.82546Z" fill="white"/>
        </svg>

      </div>
    </>
  );
}

export default AndroidIcon;