import { useCallback, useRef, useState } from "react";
// import FullPage from "../../components/FullPage";
import ReactFullpage from "../../components/FullPage";
import Wrapper from "../../components/FullPage/Wrapper";
import Header from "../../components/Header/PC";
import LoadingBar from "../../components/newLoading/lading";
import BannerComp from "../Home/banner";
import LeadComp from "../Home/Lead";
import PuppiesComp from "../Home/part2";
import LitePaper from "../litepaper";
import './index.scss'

// import ReactFullpage from "@fullpage/react-fullpage";
// const pluginWrapper = () => {
//   /**
//    * require('fullpage.js/vendors/scrolloverflow'); // Optional. When using scrollOverflow:true
//    */
// };
const NewHome = (): JSX.Element => {
  const [showLoading, setShowLoading] = useState(true)
  const [isShowLitepaper, setIsShowLitepaper] = useState(false)
  const FullPageRef = useRef<any>()
  const onClick = useCallback(() => {
    console.log('onClick');
    setIsShowLitepaper(true)

    setTimeout(() => {
      // @ts-ignore
      window.fullpage_api.moveTo(4, 0)
    }, 500);

    // setCCVisible(true);
  }, []);

  const handleEvent = (data: any) => {

    setShowLoading(data)

  };

  return (
    <>
      <div className={["newHome_loading_box", 'hide'].join(' ')}>
        <LoadingBar onEvent={handleEvent}></LoadingBar>
      </div>
      <Header onClick={onClick}></Header>
      <ReactFullpage
        // debug
        ref={FullPageRef}
        scrollOverflow
        navigation
        render={() => (
          <Wrapper>
            <div className="section">
              <BannerComp></BannerComp>

            </div>
            <div className="section">
              <PuppiesComp></PuppiesComp>
            </div>
            <div className="section">
              <LeadComp></LeadComp>
            </div>
            {
              isShowLitepaper
                ? <LitePaper />
                : ''
            }
          </Wrapper>
        )}
      />

    </>
  );
}

export default NewHome;