/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import MobileHeader from "../../../components/Header/Mobile";
import SwiperCore, { Thumbs, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useCallback, useEffect, useRef, useState } from "react";
import './index.scss'
import IOSIcon from "../../Icon/ios";
import AndroidIcon from "../../Icon/android";
import LitePaper from "../../litepaper";
import { toast, Zoom } from "react-toastify";
import LoadingBar from "../../../components/newLoading/lading";
SwiperCore.use([FreeMode, Thumbs]);

const message = [
  'Feeding, Training, and playing all happen in Augmented Reality not by tapping on the screen, but through <strong class="text-gold font-POPB">physical hand gesture detections!</strong>',
  'While walking puppies, you might find treasure boxes that contain surprises. (maybe some <strong class="text-gold font-POPB">Bitcoins?</strong>)',
  'Our puppies produce exquisite poop that turn into GOLD (monetary tokens) after extraction. <br/>But!! make sure others don’t steal the poop',
  'Scan a real pet to generate the AI version of it to acquire a perpetual companion!',
  'What happens when a Corgi breed with a German Shepard? The genetic system makes the decision with mutant possibilities!'
]
const MobileHome = (): JSX.Element => {
  const [showLoading, setShowLoading] = useState(true)
  const [thumbsSwiper] = useState<any>(null);
  const video2 = useRef<HTMLVideoElement>(null)
  const video3 = useRef<HTMLVideoElement>(null)
  const video4 = useRef<HTMLVideoElement>(null)
  const video5 = useRef<HTMLVideoElement>(null)
  const video6 = useRef<HTMLVideoElement>(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [selectIndex, setSelectIndex] = useState(0)
  const [swiperRef, setSwiperRef] = useState<any>(null);
  const [currentVideo, setCurrentVideo] = useState<any>()
  const [showTip, setShowTip] = useState(0)
  const [isDisable, setIsDisable] = useState(true)

  const [isShowLitepaper, setIsShowLitepaper] = useState(false)
  const [showMask, setShowMask] = useState(false)

  const firstPlay = useRef(true)

  const swiperBox = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setCurrentVideo(video2.current)

  }, [])

  useEffect(() => {
    if (currentVideo) {
      // currentVideo?.addEventListener('canplay', function () {
      //   currentVideo?.play()
      // });

      currentVideo.addEventListener('ended', function () {
        if (currentIndex === 0) {
          setShowTip(showTip + 1)
        }
        setIsDisable(false)
      }, false);
    }

  }, [currentIndex, currentVideo, showTip])

  useEffect(() => {


    window.addEventListener('scroll', () => {
      const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

      const scrollTop = document.documentElement.scrollTop;

      if (currentVideo) {
        const offsetTop: any = swiperBox.current?.offsetTop;
        const SHeight: any = swiperBox.current?.offsetHeight

        if ((offsetTop + SHeight / 4) - scrollTop <= 0 && firstPlay.current) {

          console.log('%c🀅 ', 'color: #007300; font-size: 20px;', 1);
          firstPlay.current = false;

          currentVideo?.play()
        }
      }

    })


  })

  const ActiveIndexChange = (e: any) => {
    // setCurrentIndex(e.activeIndex)
    // if (e.activeIndex === 0) {
    //   video1.current?.play()
    // } else if (e.activeIndex === 1) {
    //   video2.current?.play()
    // } else if (e.activeIndex === 2) {
    //   video3.current?.play()
    // } else if (e.activeIndex === 3) {
    //   video4.current?.play()
    // } else if (e.activeIndex === 4) {
    //   video5.current?.play()
    // } else {
    //   video5.current?.play()
    // }
    // setShowBtn1(false)
    // setTimeout(() => {
    //   setShowBtn1(true)
    // }, 2000)
  }

  const onClick = useCallback(() => {
    console.log('onClick');
    setIsShowLitepaper(true)
    setTimeout(() => {
      const dom = document.getElementById('litepaper');
      if (dom) {
        // window.scrollTo(dom.offsetTop, 0);
        dom.scrollIntoView({ behavior: "smooth", });
      }
    }, 500)
    // setCCVisible(true);
  }, []);

  const handleEvent = (data: any) => {
    setShowLoading(data)
  };

  return (
    <>
      <div>
        <div className={["mobile_loading_box", showLoading ? 'show' : 'hide'].join(' ')}>
          <LoadingBar onEvent={handleEvent}></LoadingBar>
        </div>
        <div className="mobileHome">
          <MobileHeader onClick={onClick}></MobileHeader>
          <div className="mobilebanner">
            <div className="bg"></div>
            <div className={['mask', showMask ? 'active' : ''].join(' ')}>
              <div className="content">
                <div className="toast">
                  <div className="text">
                    upcoming soon...
                  </div>
                </div>
              </div>
            </div>
            {/* <h1 className="title">
            A Social Game<br/> with 3D <span className="spec_text">NFT</span><br/> Puppies
            </h1>
            <p className="des">
            Take your virtual puppy for a walk in Augmented Reality to earn rewards
            </p> */}

            <div className="container">

              <div className="des">
                {/* Dummy Puppy is a Web3 social game that rewards users for real-world <span>treasure hunting</span> with NFT puppies in Augmented Reality */}
                <img src='https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/m_banner_text.png' alt="" width={'100%'} />
              </div>
              <div className="btn_group">
                <button
                  className="ios btn"
                  onClick={() => {
                    setShowMask(true)
                    setTimeout(() => {
                      setShowMask(false)
                    }, 3000)
                  }}
                >
                  <IOSIcon />
                  <span>iOS</span>
                </button>
                <button
                  className="btn"
                  onClick={() => {
                    setShowMask(true)
                    setTimeout(() => {
                      setShowMask(false)
                    }, 3000)
                  }}
                >
                  <AndroidIcon />
                  <span>Android</span>
                </button>
              </div>
            </div>


            {/* <div className="banner1">
              <img className="" src={require('../../../assets/images/banner1.png').default} width={'100%'} alt="" />
            </div> */}
          </div>
          <div className="mobileSwiper">
            <div className="content_box">
              <div className="order">
                {
                  Array.from({ length: 5 }).map((_, index) => {
                    return (
                      <>
                        <div key={index} className={['item', currentIndex === index ? 'active' : 0].join(' ')}>
                          <div className="ord">
                            0{index + 1}
                          </div>
                          <button
                            className={['btn', currentIndex === index ? 'active' : 0].join(' ')}
                            // disabled={isDisable ? currentIndex === index ? false : true : false}
                            onClick={() => {
                              swiperRef.slideTo(index, 0);
                              setCurrentIndex(index)
                              setIsDisable(true)
                              if (showTip === 0 && index === 1) {
                                setShowTip(showTip + 2)
                              } else {
                                setShowTip(showTip + 1)
                              }
                              if (index === 0) {
                                // @ts-ignore
                                video2.current.currentTime = 0
                                video2.current?.play()
                                setCurrentVideo(video2.current)
                              } else if (index === 1) {
                                // @ts-ignore
                                video3.current.currentTime = 0
                                video3.current?.play()
                                setCurrentVideo(video3.current)
                              } else if (index === 2) {
                                // @ts-ignore
                                video4.current.currentTime = 0
                                video4.current?.play()
                                setCurrentVideo(video4.current)
                              } else if (index === 3) {
                                // @ts-ignore
                                video5.current.currentTime = 0
                                video5.current?.play()
                                setCurrentVideo(video5.current)
                              } else {
                                // @ts-ignore
                                video6.current.currentTime = 0
                                video6.current?.play()
                                setCurrentVideo(video6.current)
                              }
                            }}
                          >
                            {/* {
                              index === 0 ? 'Feed & Play'
                                : index === 1 ? 'Treasure Hunting'
                                  : index === 2 ? 'Earn rewards'
                                    : index === 3 ? 'Digital Twins'
                                      : index === 4 ? 'Breeding'
                                        : ''
                            } */}
                            <img src={require(`../../../assets/images/p_btn${index + 1}.png`).default} alt="" loading="lazy"/>
                          </button>
                          {
                            index === 1 && showTip === 1
                              ? <div className="tip">
                                <img src={require('../../../assets/images/tip.png').default} alt="" width={'100%'} loading="lazy"/>
                              </div>
                              : ''
                          }
                        </div>
                      </>
                    )
                  })
                }
              </div>

              <div className="title_box">

                {
                  message.map((ele, index) => {
                    return (
                      <>
                        <div
                          key={index}
                          style={{
                            // opacity: showBtn1 ? '1' : '0',
                            display: currentIndex === index ? 'block' : 'none'
                          }}
                          className={['des font-POP', index === 5 ? 'item6' : ''].join(' ')}
                          onClick={() => {
                            // if (showBtn1) {
                            thumbsSwiper.slideTo(currentIndex + 1, 0);

                            // video1.current?.play()
                            // }
                          }}
                          dangerouslySetInnerHTML={{ __html: ele }}
                        >
                        </div>
                      </>
                    )
                  })
                }
              </div>
              <div className="arrow text-center">
                <img src={require('../../../assets/images/part2_next.png').default} alt="" width={'40%'} loading="lazy"/>
              </div>
              <div className="swiperBox" ref={swiperBox}>
                <div className="video_box">
                  <Swiper
                    modules={[FreeMode, Thumbs]}
                    slidesPerView={1}
                    className='content_swiper'
                    onSwiper={setSwiperRef}
                    onActiveIndexChange={(e) => { ActiveIndexChange(e) }}
                  >
                    <SwiperSlide className='swiperslide'>
                      <div className="video_con">
                        <video
                          ref={video2}
                          className="mint_info"
                          preload="auto"
                          muted
                          webkit-playsinline="true"
                          playsInline
                          x5-video-player-type="h5"
                          x-webkit-airplay="true"
                          poster='https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/video_loading.webp'
                          onClick={() => {
                            video2.current?.play()
                            setCurrentVideo(video2.current)
                          }}>
                          <source src='https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/gogo_002.mp4' type="video/mp4" />
                        </video>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className='swiperslide'>
                      <div className="video_con">
                        <video
                          ref={video3}
                          className="mint_info"
                          preload="none"
                          muted
                          webkit-playsinline="true"
                          playsInline
                          x5-video-player-type="h5"
                          x-webkit-airplay="true"
                          poster='https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/video_loading.webp'
                          onClick={() => {
                            video3.current?.play()
                            setCurrentVideo(video3.current)
                          }}>
                          <source src='https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/gogo_003.mp4' type="video/mp4" />
                        </video>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className='swiperslide'>
                      <div className="video_con">
                        <video
                          ref={video4}
                          className="mint_info"
                          preload="none"
                          muted
                          playsInline
                          poster='https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/video_loading.webp'
                          onClick={() => {
                            video4.current?.play()
                            setCurrentVideo(video4.current)
                          }}>
                          <source src='https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/gogo_004.mp4' type="video/mp4" />
                        </video>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className='swiperslide'>
                      <div className="video_con">
                        <video
                          ref={video5}
                          className="mint_info"
                          preload="none"
                          muted
                          playsInline
                          poster='https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/video_loading.webp'
                          onClick={() => {
                            video5.current?.play()
                            setCurrentVideo(video5.current)
                          }}>
                          <source src='https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/gogo_005.mp4' type="video/mp4" />
                        </video>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className='swiperslide'>
                      <div className="video_con">
                        <video
                          ref={video6}
                          className="mint_info"
                          preload="none"
                          muted
                          playsInline
                          poster='https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/video_loading.webp'
                          onClick={() => {
                            video6.current?.play()
                            setCurrentVideo(video6.current)
                          }}>
                          <source src='https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/gogo_006.mp4' type="video/mp4" />
                        </video>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
                <div className="bg">
                </div>
              </div>
            </div>
          </div>
          <div className="mobileLead">
            <div className="lead_logo">
              <img src={require('../../../assets/images/lead_logo.png').default} alt="" loading="lazy"/>
            </div>
            <div className="dec">
              Lead 1 billion users to <strong>earn rewards, have fun</strong> and <strong>get healthy</strong> by exploring the real-world metaverse with their puppies and friends.
            </div>
            <ul className='list'>
              <li className='item'>
                <h4 className='title'>
                  Entertainment
                </h4>
                <ul>
                  <li>
                    <p>Treasure Hunting</p>
                  </li>
                  <li>
                    <p>AR Interactions</p>
                  </li>
                  <li>
                    <p>Puppy Games</p>
                  </li>
                </ul>
              </li>
              <li className='item'>
                <h4 className='title'>
                  AI Companions
                </h4>
                <ul>
                  <li>
                    <p>Puppy Simulations</p>
                  </li>
                  <li>
                    <p>AI Chatting</p>
                  </li>
                  <li>
                    <p>Walk/Sport Buddy</p>
                  </li>
                </ul>
              </li>
              <li className='item'>
                <h4 className='title'>
                  Earnings
                </h4>
                <ul>
                  <li>
                    <p>Walk Puppy & Earn</p>
                  </li>
                  <li>
                    <p>NFT Trading</p>
                  </li>
                  <li>
                    <p>Creator Economy</p>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="pic">
              <div className="selectList">
                {
                  Array.from({ length: 8 }).map((_, index) => {
                    return (
                      <div
                        className={["item", selectIndex === index ? 'active' : ''].join(' ')}
                        key={index}
                        onClick={() => {
                          setSelectIndex(index)
                        }}
                      >
                        <img src={`https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/dog${index + 1}.png`} alt="" width={'100%'} loading="lazy"/>
                      </div>
                    )
                  })
                }
              </div>
              <div className="selectPic">
                {
                  Array.from({ length: 8 }).map((_, index) => {
                    return (
                      <video
                        className={['item', selectIndex === index ? 'active' : ''].join(' ')}
                        preload={selectIndex === index ? 'auto' : 'none'}
                        loop
                        autoPlay
                        muted
                        playsInline
                        poster='https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/video_loading.webp'
                      >
                        <source src={`https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/big_dog${index + 1}.mp4`} type="video/mp4" />
                      </video>
                    )
                  })
                }
              </div>
            </div>
          </div>
          {
            isShowLitepaper ? <div className="litepaper" id="litepaper">
              <LitePaper />
            </div>
              : ''
          }
          <div className="footer">official@poopoopuppy.xyz</div>
        </div>
      </div>
    </>
  );
}

export default MobileHome;