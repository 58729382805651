import { ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import { Transition } from "react-transition-group";
import Logo from "../../../assets/images/logo.png";
import "./index.scss";

interface Props {
  to: string,
  callback: () => void,
  children?: ReactNode,
  id: string,
}

const NavItem = ({ to, callback, children, id }: Props): JSX.Element => {

  const goToPage = () => {
    const dom = document.getElementById(to);
    if (dom) {
      dom.scrollIntoView({ behavior: "smooth", });
    }
    callback && callback();
  }

  return (
    <li className={`navlist-item`} id={id} onClick={goToPage}>
      {children}
    </li>
  );
}


const duration = 300;

const transitionStyles: any = {
  entering: { opacity: 1, zIndex: 999999 },
  entered: { opacity: 1, zIndex: 999999 },
  exiting: { opacity: 0, zIndex: -1 },
  exited: { opacity: 0, zIndex: -1 },
};
const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
  zIndex: -1,
}
interface CCProps {
  onClick: () => void;
}
const MobileHeader: React.FC<CCProps> = ({ onClick }) => {
  // const [navBtn, setNavBtnClassNames] = useState('iconUl menu');
  const [navModel, setNavModelClassNames] = useState('nav-model');
  const [modelShow, setModelShow] = useState(false)
  const [code, setCode] = useState('')
  const [tip, setTip] = useState('')

  // const changeNavStatus = () => {
  //   const isActive = navBtn.indexOf('active');
  //   if (isActive < 0) {
  //     setNavBtnClassNames('iconUl menu active opened');
  //     setNavModelClassNames('nav-model active');
  //     document.getElementsByTagName('body')[0].classList.add('overFlow')
  //   } else {
  //     setNavBtnClassNames('iconUl menu');
  //     setNavModelClassNames('nav-model');
  //     document.getElementsByTagName('body')[0].classList.remove('overFlow')
  //   }
  // }

  const liClick = () => {
    // setNavBtnClassNames('iconUl menu');
    setNavModelClassNames('nav-model');
    document.getElementsByTagName('body')[0].classList.remove('overFlow')
  }

  return (
    <>
      <div className="mobile-header">
        <div className="mobile-header-container">
          <div className="mobile-header-container-logo">
            <Link to={'/'}>
              <img src={Logo} alt="Logo" />
            </Link>
          </div>
          <div className="mobile-header-container-spec"></div>
          <div className="right">
            <div className='header-nav-item-title' onClick={() => { setModelShow(true) }}>
              Litepaper
            </div>
          </div>
          {/* <div className="mobile-header-container-navBtn icon">
            <button className={navBtn} aria-label="Main Menu" onClick={changeNavStatus}>
              <svg width="32" height="32" viewBox="0 0 100 100">
                <path className="line line1"
                  d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                <path className="line line2" d="M 20,50 H 80" />
                <path className="line line3"
                  d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
              </svg>
            </button>
          </div> */}
        </div>
      </div>

      <div className={navModel}>
        <div className="model-content" onClick={liClick}>
          <div className="phoneNav">
            <ul className="navList textList">
              <NavItem to="banner" id='nav-banner' callback={liClick}>
                <span>
                  Home
                </span>
              </NavItem>
              <NavItem to="products" id='nav-products' callback={liClick}>
                <span>
                  Products
                </span>
              </NavItem>
              <NavItem to="backers" id='nav-backers' callback={liClick}>
                <span>
                  Backers
                </span>
              </NavItem>
              <a href="https://cultured-clerk-7d7.notion.site/NXG-Labs-3116be57f0e9409290a4f40b68199714" target='_blank' rel="noreferrer">
                <NavItem to='careers' id='nav-careers' callback={liClick}>
                  <span>
                    Careers
                  </span>
                </NavItem>
              </a>
            </ul>
          </div>
        </div>
      </div>
      <Transition in={modelShow} timeout={duration}>
        {state => (
          <div className="mobileLitepaperModel"
            style={{
              ...defaultStyle,
              ...transitionStyles[state]
            }}
          >
            <div className="content">
              <h1 className='title'>Invitation code</h1>
              <div className="input_item">
                <input onChange={(event) => {
                  setTip('')
                  setCode(event.target.value)
                }} value={code} type="text" placeholder='Please enter invitation code' />
              </div>
              <p
                style={{
                  color: 'red',
                  fontSize: '12px',
                  marginBottom: '5px',
                  height: '14px'
                }}
              >{tip}</p>
              <div className="btnGroup">
                <button className='btn1' onClick={() => { setModelShow(false) }}>
                  cancel
                </button>
                <button className='btn2'
                  onClick={() => {
                    if (code === 'dummypuppy') {
                      // window.open('/litepaper', '_self')
                      onClick()
                      setModelShow(false)
                    } else {
                      setTip('Invitation code input error!')
                    }
                  }}
                >
                  confirm
                </button>
              </div>
            </div>
          </div>
        )}

      </Transition>
    </>
  );
}

export default MobileHeader;
