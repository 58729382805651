/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-escape */
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Pagination } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.css';
import './index.scss'
import { useEffect, useRef, useState } from 'react';
SwiperCore.use([EffectFade, Pagination]);


const message = [
  'Feeding, Training, and playing all happen in Augmented Reality not by tapping on the screen, but through <strong class="text-gold font-POPB">physical hand gesture detections!</strong>',
  'While walking puppies, you might find treasure boxes that contain surprises. (maybe some <strong class="text-gold font-POPB">Bitcoins?</strong>)',
  'Our puppies produce exquisite poop that turn into GOLD (monetary tokens) after extraction. <br/>But!! make sure others don’t steal the poop',
  'Scan a real pet to generate the AI version of it to acquire a perpetual companion!',
  'What happens when a Corgi breed with a German Shepard? The genetic system makes the decision with mutant possibilities!'
]

const PuppiesComp = (): JSX.Element => {
  // const video1 = useRef<HTMLVideoElement>(null)
  const video2 = useRef<HTMLVideoElement>(null)
  const video3 = useRef<HTMLVideoElement>(null)
  const video4 = useRef<HTMLVideoElement>(null)
  const video5 = useRef<HTMLVideoElement>(null)
  const video6 = useRef<HTMLVideoElement>(null)
  const [swiperRef, setSwiperRef] = useState<any>(null);
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentVideo, setCurrentVideo] = useState<any>()

  const puppyPage = useRef<HTMLDivElement>(null)

  const [showTip, setShowTip] = useState(0)
  const [isDisable, setIsDisable] = useState(true)

  const firstPlay = useRef(true)

  useEffect(() => {
    setCurrentVideo(video2.current)

    console.log('%c🀀 ', 'color: #e50000; font-size: 20px;', video2.current?.duration);
  }, [])

  useEffect(() => {
    if (currentVideo) {
      // currentVideo?.addEventListener('canplay', function () {
      //   currentVideo?.play()
      // });

      currentVideo.addEventListener('ended', function () {
        if (currentIndex === 0) {
          setShowTip(showTip + 1)
        }
        setIsDisable(false)
      }, false);
    }

  }, [currentIndex, currentVideo, showTip])


  useEffect(() => {
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;


    const SHText = `translate3d(0px, -${screenHeight}px, 0px)`

    window.addEventListener('mouseover', () => {
      videoAutoPlay()
    })

    window.addEventListener('wheel', () => {

      videoAutoPlay()
    })

    const videoAutoPlay = () => {
      //@ts-ignore
      if (window.fullpage_api.test.translate3d === SHText && firstPlay.current) {

        if (currentVideo) {
          //@ts-ignore
          console.log('%c🀄︎ ', 'color: #1d5673; font-size: 20px;', currentVideo);
          firstPlay.current = false;

          currentVideo?.play()
        }
      }
    }

  })


  const ActiveIndexChange = (e: any) => {
    // setCurrentIndex(e.activeIndex)
    // setShowBtn1(false)
    // setTimeout(() => {
    //   setShowBtn1(true)
    // }, 4000)
  }
  return (
    <>
      <div className="puppy" ref={puppyPage}>
        <div className="scroll-sequence">
          <div className="container">
            <div className="black_bg">
              <img src={require('../../../assets/images/part2_bg.png').default} alt="" width={'100%'} />
            </div>
            <div className="videoBox">
              <div className="video_con">
                <Swiper
                  className='swiper-container'
                  effect="fade"
                  onSlideChange={() => console.log('slide change')}
                  onActiveIndexChange={(e) => { ActiveIndexChange(e) }}
                  onSwiper={setSwiperRef}
                >
                  <SwiperSlide
                    className='swiperslide'
                  >
                    <video
                      ref={video2}
                      className="mint_info"
                      preload="auto"
                      muted
                      playsInline
                      poster='https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/video_loading.webp'
                      onClick={() => {
                        video2.current?.play()
                        setCurrentVideo(video2.current)
                      }}
                    >
                      <source src='https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/gogo_002.mp4' type="video/mp4" />
                    </video>
                  </SwiperSlide>
                  <SwiperSlide
                    className='swiperslide'
                  >
                    <video
                      ref={video3}
                      className="mint_info"
                      preload="none"
                      muted
                      playsInline
                      poster='https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/video_loading.webp'
                      onClick={() => {
                        video3.current?.play()
                        setCurrentVideo(video3.current)
                      }}
                    >
                      <source src='https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/gogo_003.mp4' type="video/mp4" />
                    </video>
                  </SwiperSlide>
                  <SwiperSlide
                    className='swiperslide'
                  >
                    <video
                      ref={video4}
                      className="mint_info"
                      preload="none"
                      muted
                      playsInline
                      poster='https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/video_loading.webp'
                      onClick={() => {
                        video4.current?.play()
                        setCurrentVideo(video4.current)
                      }}
                    >
                      <source src='https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/gogo_004.mp4' type="video/mp4" />
                    </video>
                  </SwiperSlide>
                  <SwiperSlide
                    className='swiperslide'
                  >
                    <video
                      ref={video5}
                      className="mint_info"
                      preload="none"
                      muted
                      playsInline
                      poster='https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/video_loading.webp'
                      onClick={() => {
                        video5.current?.play()
                        setCurrentVideo(video5.current)
                      }}
                    >
                      <source src='https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/gogo_005.mp4' type="video/mp4" />
                    </video>
                  </SwiperSlide>
                  <SwiperSlide
                    className='swiperslide'
                  >
                    <video
                      ref={video6}
                      className="mint_info"
                      preload="none"
                      muted
                      playsInline
                      poster='https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/video_loading.webp'
                      onClick={() => {
                        video6.current?.play()
                        setCurrentVideo(video6.current)
                      }}
                    >
                      <source src='https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/gogo_006.mp4' type="video/mp4" />
                    </video>
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className="bg">

              </div>
            </div>
            <div className="top_open">
              <img src={require('../../../assets/images/top_open.png').default} alt="" />
            </div>

            <div className="textBox font-POP">
              <div className="text">
                {
                  message.map((ele, index) => {
                    return (
                      <>
                        <div className={['item', index === 5 ? 'item6' : '', currentIndex === index ? 'active' : ''].join(' ')} key={index}>
                          <div
                            style={{
                              // opacity: showBtn1 ? '1' : '0',
                            }}
                            className='des'
                            // onClick={() => {
                            //   swiperRef.slideTo(currentIndex + 1, 0);
                            // }}
                            dangerouslySetInnerHTML={{ __html: ele }}
                          >
                          </div>

                        </div>
                      </>
                    )
                  })
                }
              </div>
              <div className="btn_group">
                {
                  Array.from({ length: 5 }).map((_, index) => {
                    return (
                      <>
                        <div key={index} className={['item', currentIndex === index ? 'active' : 0].join(' ')}>
                          <div className="ord">
                            0{index + 1}
                          </div>
                          <button
                            className={['btn', currentIndex === index ? 'active' : 0].join(' ')}
                            // disabled={currentIndex === index && ! ? false : true}
                            // disabled={isDisable ? currentIndex === index ? false : true : false}
                            onClick={() => {
                              swiperRef.slideTo(index, 0);
                              setCurrentIndex(index)
                              setIsDisable(true)
                              if (showTip === 0 && index === 1) {
                                setShowTip(showTip + 2)
                              } else {
                                setShowTip(showTip + 1)
                              }
                              if (index === 0) {
                                // @ts-ignore
                                video2.current.currentTime = 0
                                video2.current?.play()

                                setCurrentVideo(video2.current)
                              } else if (index === 1) {
                                // @ts-ignore
                                video3.current.currentTime = 0
                                video3.current?.play()
                                setCurrentVideo(video3.current)

                              } else if (index === 2) {
                                // @ts-ignore
                                video4.current.currentTime = 0
                                video4.current?.play()
                                setCurrentVideo(video4.current)

                              } else if (index === 3) {
                                // @ts-ignore
                                video5.current.currentTime = 0
                                video5.current?.play()
                                setCurrentVideo(video5.current)
                              } else {
                                // @ts-ignore
                                video6.current.currentTime = 0
                                video6.current?.play()
                                setCurrentVideo(video6.current)
                              }
                            }}
                          >
                            {/* {
                              index === 0 ? 'Feed & Play'
                                : index === 1 ? 'Treasure Hunting'
                                  : index === 2 ? 'Earn rewards'
                                    : index === 3 ? 'Digital Twins'
                                      : index === 4 ? 'Breeding'
                                        : ''
                            } */}
                            <img src={require(`../../../assets/images/p_btn${index + 1}.png`).default} alt="" />
                          </button>
                          {
                            index === 1 && showTip === 1
                              ? <div className="tip">
                                <img src={require('../../../assets/images/tip.png').default} alt="" width={'100%'} />
                              </div>
                              : ''
                          }
                        </div>
                      </>
                    )
                  })
                }
              </div>



            </div>
            <div className="arrow">
              <img src={require('../../../assets/images/part2_arrow.png').default} alt="" />
            </div>
            <div className="star">
              <img src={require('../../../assets/images/part2_star.png').default} alt="" />
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default PuppiesComp;