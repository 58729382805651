/* eslint-disable jsx-a11y/anchor-is-valid */
import AndroidIcon from "../../Icon/android";
import IOSIcon from "../../Icon/ios";
import "./index.scss";
import { useState } from "react";

const BannerComp = (): JSX.Element => {
  const [showMask, setShowMask] = useState(false)
  // @ts-ignore
  return (
    <>
      <div className="banner">
        <div className="bg">
          <img src='https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/banner_small.jpg' alt="" height={'100%'} width={'100%'} loading="lazy"/>
          <img src='https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/video_loading.webp' hidden={true} alt=""/>
        </div>
        <div className={['mask', showMask ? 'active' : ''].join(' ')}>
          <div className="content">
            <div className="toast">
              <div className="text">
                upcoming soon...
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="bannerLogo">
            <img src='https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/banner_logo.png' alt="" width={'100%'}/>
          </div>
          <div className="des">
            <img src='https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/banner_text.png' alt="" width={'100%'}/>
            {/* <div className="stroke">
              Dummy Puppy is a Web3 social game that rewards users for real-world <span>treasure hunting</span>  with NFT puppies in Augmented Reality
            </div>
            <div className="text">
              Dummy Puppy is a Web3 social game that rewards users for real-world <span>treasure hunting</span> with NFT puppies in Augmented Reality
            </div> */}
          </div>
          <div className="btn_group">
            <button className="ios btn"
              onClick={() => {
                setShowMask(true)
                setTimeout(() => {
                  setShowMask(false)
                }, 3000)
              }}
            >
              <IOSIcon />
              <span>iOS</span>
            </button>
            <button
              onClick={() => {
                setShowMask(true)
                setTimeout(() => {
                  setShowMask(false)
                }, 3000)
              }}
              className="btn"
            >
              <AndroidIcon />
              <span>Android</span>
            </button>
          </div>
          {/* <div className="left">
            <h1 className="title">
            A Social Game<br/> with 3D <span className="spec_text">NFT</span><br/> Puppies
            </h1>
            <p className="des">
            Take your virtual puppy for a walk in Augmented Reality to earn rewards
            </p>
            <div className="btn_group">
              <a href="#" className="ios" target='_blank' rel="noreferrer">
                <IOSIcon/>
                <span>iOS</span>
              </a>
              <a href="#" target='_blank' rel="noreferrer">
                <AndroidIcon/>
                <span>Android</span>
              </a>
            </div>
          </div>
          <div className="right">
            <img className="banner1" src={require('../../../assets/images/banner1.png').default} alt="" />
          </div> */}
        </div>
      </div>
    </>
  );
}

export default BannerComp;
