/* eslint-disable jsx-a11y/anchor-is-valid */
import { ReactNode, useState } from 'react';
import Logo from '../../../assets/images/logo.png';
import { Transition } from 'react-transition-group';

import './index.scss';

const duration = 300;


interface Props {
  to: string,
  children?: ReactNode,
  id?: string,
}

const NavItem = ({ to, children, id }: Props): JSX.Element => {
  const goToPage = () => {
    const dom = document.getElementById(to);
    if (dom) {
      dom.scrollIntoView({ behavior: 'smooth' });
      if (to === 'backers') {
        setTimeout(() => {
          dom.scrollIntoView({ behavior: 'smooth' });
        }, 1000)
      }
    }
  }

  return (
    <div className='header-nav-item' id={id} onClick={goToPage}>
      {children}
    </div>
  );
}

const transitionStyles: any = {
  entering: { opacity: 1, zIndex: 999999 },
  entered: { opacity: 1, zIndex: 999999 },
  exiting: { opacity: 0, zIndex: -1 },
  exited: { opacity: 0, zIndex: -1 },
};
const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
  zIndex: -1,
}

interface CCProps {
  onClick: () => void;
}


const Header: React.FC<CCProps> = ({ onClick }) => {
  const [modelShow, setModelShow] = useState(false)
  const [code, setCode] = useState('')
  const [tip, setTip] = useState('')
  return (
    <>
      <div className='header'>
        <div className='header-blur' />
        <div className='header-wrap'>
          <div className='header-logo'>
            <a>
              <img src={Logo} alt='Logo' />
            </a>
          </div>
          <nav className='header-nav'>
            <NavItem to=''>
              <div className='header-nav-item-title' onClick={() => { setModelShow(true) }}>
                Litepaper
              </div>
              <span className='header-nav-item-doll' />
            </NavItem>
            {/* <NavItem to=''>
              <a   href="" target='_blank' rel="noreferrer">

                <div className='header-nav-item-title'>
                  <img src={require('../../../assets/images/twitter.png').default} alt="" />
                </div>
              </a>
            </NavItem>
            <NavItem to=''>
              <a  href="" target='_blank' rel="noreferrer">

                <div className='header-nav-item-title'>
                  <img src={require('../../../assets/images/discord.png').default} alt="" />
                
                </div>
              </a>
            </NavItem>

            <NavItem to=''>
              <a  href="" target='_blank' rel="noreferrer">

                <div className='header-nav-item-title'>
                  <img src={require('../../../assets/images/telegram.png').default} alt="" />
                
                </div>
              </a>
            
            </NavItem> */}
          </nav>
        </div>
      </div>

      <Transition in={modelShow} timeout={duration}>
        {state => (
          <div className="LitepaperModel"
            style={{
              ...defaultStyle,
              ...transitionStyles[state]
            }}
          >
            <div className="content">
              <h1 className='title'>Invitation code</h1>
              <div className="input_item">
                <input onChange={(event) => {
                  setTip('')
                  setCode(event.target.value)
                }} value={code} type="text" placeholder='Please enter invitation code' />
              </div>
              <p
                style={{
                  color: 'red',
                  fontSize: '12px',
                  height: '14px',
                  textAlign: 'center',
                  marginTop: '-20px',
                  marginBottom: '30px'
                }}
              >{tip}</p>
              <div className="btnGroup">
                <button className='btn1' onClick={() => { setModelShow(false) }}>
                  cancel
                </button>
                <button className='btn2'
                  onClick={() => {
                    if (code === 'dummypuppy') {
                      // window.open('/litepaper', '_self')
                      onClick()
                      setModelShow(false)
                    } else {
                      setTip('Invitation code input error!')
                    }
                  }}
                >
                  confirm
                </button>
              </div>
            </div>
          </div>
        )}

      </Transition>
    </>
  );
}

export default Header;
