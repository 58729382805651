import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
// import Home from './pages/Home';
import Wallet from './pages/wallet';
import HttpPage from './pages/Request';
import MobileHome from './pages/mobile/home';
import { isMobile } from './libs/userAgent';
import NewHome from './pages/newhome';
// import LitePaper from './pages/litepaper';



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={isMobile() ? <MobileHome /> : <NewHome />} />
        {/* <Route path='/litepaper' element={<LitePaper />}></Route> */}
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/http" element={<HttpPage />} />
        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Routes>
      <ToastContainer theme="dark" autoClose={3000} hideProgressBar />
    </Router>
  );
}

export default App;
