
import React, { useState, useEffect, useRef } from 'react';
import './loading.scss'
import axios from 'axios';


function LoadingBar(props) {
  const [progress, setProgress] = useState(0);
  const currentProgress = useRef(0)

  const dealyTime = useRef(0)

  useEffect(() => {
    let delayTimer
    const delayFun = () => {

      console.log('%c🀂 ', 'color: #00736b; font-size: 20px;', 121);
      delayTimer = setInterval(() => {
        dealyTime.current = dealyTime.current + 1
        handleResourceLoad()
      }, 500)
    }


    const resources = Array.from(document.querySelectorAll('img, video, audio'));
    const numResources = resources.length;
    let numLoadedResources = 0;

    const handleResourceLoad = () => {
      // numLoadedResources++;
      // currentProgress.current = (numLoadedResources / numResources) * 100
      // setProgress((numLoadedResources / numResources) * 100);
      numLoadedResources++;

      if (dealyTime.current < 10) {
        setProgress(dealyTime.current);
      } else {

        currentProgress.current = (numLoadedResources / numResources) * 100
        if (currentProgress.current >= 99) {
          setProgress(100);
          clearInterval(delayTimer)
          props.onEvent(false);
          return
        }
        if (currentProgress.current < dealyTime.current) {
          // clearInterval(delayTimer)
        } else {
          setProgress(currentProgress.current);
        }

      }


    };

    delayFun()
    resources.forEach(resource => {
      if (resource.complete) {
        handleResourceLoad();
      } else {
        resource.addEventListener('load', handleResourceLoad);
        resource.addEventListener('error', handleResourceLoad);
      }
    });


    const timeoutId = setTimeout(() => {

      clearInterval(delayTimer)

      resources.forEach(resource => {
        resource.removeEventListener('load', handleResourceLoad);
        resource.removeEventListener('error', handleResourceLoad);
      });
      setProgress(100);
      if (currentProgress.current >= 100) return


      setTimeout(() => { props.onEvent(false) }, 200)
    }, 10000);

    return () => {
      clearTimeout(timeoutId);
      resources.forEach(resource => {
        resource.removeEventListener('load', handleResourceLoad);
        resource.removeEventListener('error', handleResourceLoad);
      });
    };
  }, []);


  useEffect(() => {

  }, []);

  const getArrayBuffer = async (url) => {
    try {
      const response = await axios({
        method: 'get',
        url: url,
        responseType: 'arraybuffer'
      });
      const arrayBuffer = response.data;
      return arrayBuffer
    } catch (error) {
      console.error(error);
    }
  }

  const processText = [
    "Ready for liftoff?",
    "Get set for non-stop fun!",
    "Grab a snack to fuel up!",
    "Brace for the ride!",
    "Feel the excitement!",
    "The destination is a blast!",
    "All is set and ready for you!",
    "Your heart will race like never before!",
    "The wait is almost over!",
    "Here goes nothing!"
  ]
  const [text, setText] = useState("Ready for liftoff?")

  useEffect(() => {
    let textIndex = 0;
    const textTime = setInterval(() => {
      if (textIndex >= 9) {
        textIndex = 0
      } else {
        textIndex++
      }
      setText(processText[textIndex])
    }, 500);
    return () => {
      clearTimeout(textTime);
    }
  }, [])
  return (
    <div className="loading-bar">
      <div className="loading-bar__fill" >
        <img src={require('../../assets/images/loading.webp').default} alt="" />
        {/* <canvas className="loading_canvas" id="pag"></canvas> */}
        {/* <video src={loadingVideo} muted loop autoPlay></video> */}
        {/* <p className='num'>{Math.round(progress)}%</p> */}
        {/* <p className='text'>{text}</p> */}
      </div>
    </div >
  );
}

export default LoadingBar;
