// import ReactFullpage from "../../components/FullPage/ReactFullpage";
// import Wrapper from "../../components/FullPage/Wrapper";
// import { isMobile } from "../../helpers/utilities";
import './index.scss'


const LitePaper = (): JSX.Element => {
  return (
    <>
      {/* <div className="w-full LitePaper">
        {
          isMobile()
            ? <>

              {
                Array.from({ length: 7 }).map((_, index) => {
                  return (
                    <>
                      {
                        index !== 2
                          ? <div className="section" key={index}>
                            <img src={require(`../../assets/images/litepaper/pic${index + 1}.png`).default} alt="" width={'100%'} style={{
                              display: 'block'
                            }} />
                          </div>
                          : <div className="section" key={index}>
                            <div className="wrap">
                              <div className="dog">
                                <img src={require('../../assets/images/big_dog1.webp').default} alt="" width={'100%'} />
                              </div>
                              <img src={require(`../../assets/images/litepaper/pic${index + 1}.png`).default} alt="" width={'100%'} />
                            </div>
                          </div>
                      }

                    </>
                  )
                })
              }
            </>

            : <ReactFullpage
              navigation
              render={() => (
                <Wrapper>

                  {
                    Array.from({ length: 7 }).map((_, index) => {
                      return (
                        <>
                          {
                            index !== 2
                              ? <div className="section" key={index}>
                                <div className="wrap">
                                  <img src={require(`../../assets/images/litepaper/pic${index + 1}.png`).default} alt="" width={'100%'} style={{
                                    display: 'block'
                                  }} />
                                </div>

                              </div>
                              : <div className="section" key={index}>
                                <div className="wrap">
                                  <div className="dog">
                                    <img src={require('../../assets/images/big_dog1.webp').default} alt="" width={'100%'} />
                                  </div>
                                  <img src={require(`../../assets/images/litepaper/pic${index + 1}.png`).default} alt="" width={'100%'} />
                                </div>
                              </div>
                          }

                        </>
                      )
                    })
                  }
                </Wrapper>
              )}
            />


        }



      </div> */}

      {
        Array.from({ length: 7 }).map((_, index) => {
          return (
            <>
              {
                index !== 2
                  ? <div className={['section',].join(' ')} key={index}>
                    <div className={['wrap LitePaper', `LitePaper-${index}`].join(' ')}>
                      <img src={require(`../../assets/images/litepaper/pic${index + 1}.png`).default} alt="" style={{display: 'block',maxHeight: '100vh',maxWidth: '100vw'}}/>
                    </div>
                  </div>
                  : <div className="section" key={index}>
                    <div className={['wrap LitePaper', `LitePaper-${index}`].join(' ')}>
                      <div className="dog">
                        <img src='https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/big_dog1.webp' alt="" width={'100%'}/>
                      </div>
                      <img src={require(`../../assets/images/litepaper/pic${index + 1}.png`).default} alt="" style={{display: 'block',maxHeight: '100vh',maxWidth: '100vw'}}/>
                    </div>
                  </div>
              }
            </>
          )
        })
      }
    </>
  );
}

export default LitePaper;