import { useState } from "react";
import "./index.scss";
const LeadComp = (): JSX.Element => {
  const [selectIndex, setSelectIndex] = useState(0);

  return (
    <>
      <div className="lead">
        <div className="bg"></div>
        <div className="container">
          <div className="left">
            <div className="lead_logo">
              <img
                src={require("../../../assets/images/lead_logo.png").default}
                alt=""
              />
            </div>
            <div className="dec font-POP">
              Lead 1 billion users to{" "}
              <strong className="font-POPB">earn rewards, have fun</strong> and{" "}
              <strong className="font-POPB">get healthy</strong> by exploring
              the real-world metaverse with their puppies and friends.
            </div>
            <ul className="list">
              <li className="item">
                <h4 className="title">Entertainment</h4>
                <ul>
                  <li>
                    <p>Treasure Hunting</p>
                  </li>
                  <li>
                    <p>AR Interactions</p>
                  </li>
                  <li>
                    <p>Puppy Games</p>
                  </li>
                </ul>
              </li>
              <li className="item">
                <h4 className="title">AI Companions</h4>
                <ul>
                  <li>
                    <p>Puppy Simulations</p>
                  </li>
                  <li>
                    <p>AI Chatting</p>
                  </li>
                  <li>
                    <p>Walk/Sport Buddy</p>
                  </li>
                </ul>
              </li>
              <li className="item">
                <h4 className="title">Earnings</h4>
                <ul>
                  <li>
                    <p>Walk Puppy & Earn</p>
                  </li>
                  <li>
                    <p>NFT Trading</p>
                  </li>
                  <li>
                    <p>Creator Economy</p>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="right">
            <div className="pic">
              {/* <img src={require('../../../assets/images/lead_pic.png').default} alt="" /> */}
              <div className="selectList">
                {Array.from({ length: 8 }).map((_, index) => {
                  return (
                    <div
                      className={[
                        "item",
                        selectIndex === index ? "active" : "",
                      ].join(" ")}
                      key={index}
                      onClick={() => {
                        setSelectIndex(index);
                      }}
                    >
                      <img
                        src={
                          require(`../../../assets/images/dog${index + 1}.png`)
                            .default
                        }
                        alt=""
                        width={"100%"}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="selectPic">
                {/* <img ref={dogImg} className={['item'].join(' ')} src={require(`../../../assets/images/big_dog${selectIndex + 1}.webp`).default} alt="" width={'100%'} /> */}
                {Array.from({ length: 8 }).map((_, index) => {
                  return (
                    <img
                      className={[
                        "item",
                        selectIndex === index ? "active" : "",
                      ].join(" ")}
                      src={`https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/big_dog${index + 1}.webp`}
                      alt=""
                      width={"100%"}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="footer">official@poopoopuppy.xyz</div>
      </div>
    </>
  );
};

export default LeadComp;
