const IOSIcon = (): JSX.Element => {
  return (
    <>
      <div className="iconBox">
        <svg preserveAspectRatio="xMinYMin meet" viewBox="0 0 27 32" fill="none">
          <path d="M27 23.4545C26.9584 23.5759 26.3075 25.7668 24.7084 28.0204C23.3235 29.9645 21.8908 31.9248 19.6304 31.9592C17.4054 31.9976 16.6942 30.6786 14.1552 30.6786C11.6203 30.6786 10.8239 31.9248 8.72154 31.9976C6.54851 32.0826 4.87454 29.8735 3.48754 27.9395C0.644916 23.9765 -1.5406 16.7099 1.39144 11.8406C2.83667 9.40285 5.42976 7.85527 8.25366 7.81076C10.383 7.77233 12.4084 9.20258 13.7018 9.20258C15.0306 9.20258 17.4802 7.47292 20.065 7.73187C21.1463 7.77637 24.174 8.16681 26.1245 10.8958C25.9727 10.997 22.5021 12.9714 22.5416 16.9931C22.5936 21.8463 26.9543 23.4384 27 23.4545ZM18.3744 5.1303C19.5389 3.75871 20.3062 1.87329 20.1045 0C18.4409 0.0627127 16.4114 1.06612 15.2074 2.43163C14.1406 3.62115 13.2028 5.53692 13.4648 7.38796C15.3134 7.49518 17.2141 6.45334 18.3723 5.1303" fill="black"/>
        </svg>

      </div>
    </>
  );
}

export default IOSIcon;